import React, {PropsWithChildren, useEffect} from 'react';
import {useHalState} from '@synako/haligator';
import i18next from 'i18next';

/**
 * Provider qui gère le changement de langue basé sur les préférences du client
 * Surveille les changements de langue dans les données du client et met à jour i18next
 */
export const ClientLanguageProvider: React.FC<PropsWithChildren<{
  clientHook: ReturnType<typeof useHalState>
}>> = ({children, clientHook}) => {
  useEffect(() => {
    if (!clientHook.data) return;
    const langue = clientHook.data.tolede.langue_des_documents;
    if (langue && langue !== i18next.language) {
      // Mise à jour de i18next
      i18next.changeLanguage(langue);
    }
  }, [clientHook.data]);

  return <>{children}</>;
};

export default ClientLanguageProvider;