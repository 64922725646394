import React from 'react';
import {BreakpointProvider} from '@/chakra/BreakpointProvider';
import {Outlet, ScrollRestoration} from 'react-router-dom';
import {Footer} from '../orga/Footer';

/**
 * Composant pour l'application Catalogue Grand Compte
 */
export const CatalogueGrandCompteApp: React.FC = () => {
  return (
    <BreakpointProvider>
      <ScrollRestoration />
      <Outlet />
      <Footer nomenclature_client={false} />
      
    </BreakpointProvider>
  );
};
