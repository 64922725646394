import React from 'react';
import {routes} from 'ff-common';
import {fromRoute} from '@synako/enhanced-router';
import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {Box, Flex, Image, Text, VStack} from '@chakra-ui/react';
import {imageOptimisee} from '../mediatheque/optimisation';
import {useTranslation} from 'react-i18next';
import {PartieGaucheConnexion} from './PartieGaucheConnexion';

// eslint-disable-next-line react-refresh/only-export-components
export const maintenanceRoute = fromRoute(routes.index, {
  path: 'maintenance'
});

// gestion de la redirection si elle est demandée
// redirect_after_login oit être une URL valide
const params = new URLSearchParams(location.search);
const message = params.get('message');

export const Maintenance: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Flex minHeight="100vh"
      direction={{base: 'column', md: 'row'}}
      bg='white'>
    
      {/* Partie gauche - cachée sur mobile */}
      <Box position="fixed"
        top={0}
        left={0}
        maxWidth="800px"
        width="40%"
        height="100vh"
        display={{base: 'none', md: 'block'}}>
        <PartieGaucheConnexion />
      </Box>
    
      {/* Partie droite */}
      <Box marginLeft={{base: 0, md: '40%'}}
        width={{base: '100%', md: '60%'}}
        minHeight="100vh"
        overflowY="auto">
        <Flex width="100%"
          height="100%"
          justify="center"
          align="center"
          py={8}>
    
          <VStack 
            bg='white'
            minHeight='100vh'
            width='100%'
            justify='center'
            align='center'
            spacing={8}
          >
            <Image 
              src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)}
              alt={t('global.alt_logo_france_frais')}
              maxWidth="300px"
              mb={2}
            />
            <Text fontSize="xl"
              fontWeight="bold">
              {t('maintenance.title')}
            </Text>
            <Text textAlign="center"
              maxWidth="600px">
              {message}
            </Text>
            <Text fontSize="sm"
              color="gray.500">
              {t('maintenance.contact_support')}
            </Text>
          </VStack>
        </Flex>
      </Box>
    </Flex>
      
  );
};
