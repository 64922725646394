import {Footer} from '@/domains/orga/Footer';
import {Navbar} from '@/components/Navbar/Navbar';
import {BottomNav} from '@/components/Navbar/BottomNav/BottomNav';
import {Box, Portal, VStack, useToast} from '@chakra-ui/react';
import {Outlet, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {ConsentementAnalytics} from '@/components/Analytics/ConsentementAnalytics';

export const Layout: React.FC = () => {
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    // fermeture de tous les toasts si on change de page
    toast.closeAll();
  }, [location, toast]);
  return (
    <>
      <VStack gap='0'
        minH='100vh'
        position='relative'>
        <Navbar/>
        <Box position='relative'
          w="100vw"
          flexGrow='1'>
          <Outlet/>
        </Box>
        <Footer />
      </VStack>
      <Portal>
        <ConsentementAnalytics />
        <BottomNav />
      </Portal>
    </>
  );
};